

@media only screen and (min-width: 1280px) and (max-width: 1500px){
    .bttn{
        margin-right: -190px !important;
        margin-left: -190px !important;
    }
}

/*    Device = Laptops, Desktops
---------------------------------------------------------------- */
@media only screen and (min-width: 992px) and (max-width: 1280px){
    .links{
        text-align: center;
        margin-left: 10% !important;
      }
    .header-container{
        width: 100%;  
        overflow-x: hidden !important;   
    }
    .header-logo{
          top: 400px;
      }
    .bttn{
        margin-left: 0 !important;
        margin-right: 0 !important;
        padding-bottom: 50px !important;
    }
    #vision-conatiner{
        width: 100%;
    }
    .vision-conatiner{
        width: 100%;
    }
    .tokonomics{
        width: 100%;
    }
    .chart-container{
        padding-left: 250px !important;
        padding-right: 250px !important;  
    }


    /*ROadmap*/
    #roadmap{
        width: 100%;
    }
    .roadmap_image img{
        margin-left: 0;
    }
    /*.roadmap_centerbar img{
        left: 46%;
    }*/
}


/*		Tablet Layout: 768px.
---------------------------------------------------------------- */

@media only screen and (min-width: 768px) and (max-width: 991px){
    /*Header*/
    .header-container{
        width: 100%;
        overflow-x: hidden;        
    }
    .navbarBg{
        background: none !important;
        background-color: #6a5534 !important;
        border-bottom: #000000 5px solid !important;
        border-bottom-left-radius: 50px;
        border-bottom-right-radius: 50px;
        opacity: 0.9;
    }
    .links{
        margin-left: 0 !important;
    }
    .header-logo{
        top: 400px;
    }
    .bttn{
        margin-left: 0 !important;
        margin-right: 0 !important;
        padding-bottom: 40px !important;
    }
    video{
        width: 100%;
        overflow-x: hidden;
    }
    .header-logo img{
		width: 60%;
	}
    /*About*/
    #vision-conatiner{
        width: 100%;
    }
    .vision-conatiner{
        width: 100%;
    }
    /*Tokenomics*/
    .tokonomics{
        width: 100%;
        overflow-x: hidden;
    }
    .chart-container{
        padding-left: 80px !important;
        padding-right: 80px !important;
    }
   /* chart{
        width: 400px;
        height: 400px;
    }*/
    /*ROadmap*/
    #roadmap{
        width: 100%;
        overflow-x: hidden;
    }
    .roadmap .roadmap-area{
		background: none;
	}

		.roadmap_image{
			display: none;
		}
		.roadmap_centerbar{
			display: none;
		}
		.para-1{
			padding-top: 50px !important;
		}
		.roadmap-paragraphs{
			text-align: center !important;
			margin-right: 70px !important;
		}

		.hoverimg1:hover .roadmap_image{
			display: none !important;
		}
		
		.hoverimg2:hover .roadmap_image{
			display: none !important;
		}
		
		.hoverimg3:hover .roadmap_image{
			display: none !important;
		}

        /*contact*/

        .submit-btn{
            width: 25%;
        }

        /*icons*/
        .social-container{
            margin-top: 17%;
            margin-left: 3px;
        }
        .social{
            padding-right: 15px;
        }
}


/*		Mobile Layout: 320px.
---------------------------------------------------------------- */
@media only screen and (max-width: 767px){
    /*Header*/
    .header-container{
        width: 100%;
        overflow-x: hidden;
    }
    .navbarBg{
        background: none !important;
        background-color: #6a5534 !important;
        border-bottom: #000000 5px solid !important;
        border-bottom-left-radius: 40px;
        border-bottom-right-radius: 40px;
        opacity: 0.9;
    }
    .links{
        margin-left: 0 !important;
    }
    .header-logo{
        top: 400px;
    }
    .bttn{
        margin-left: 0 !important;
        margin-right: 0 !important;
        padding-bottom: 30px !important;
    }
    video{
        width: 100%;
        overflow-x: hidden;
    }
    .header-logo{
        margin-left: 15px;
    }
    .header-logo img{
		width: 100%;
	}
    /*About*/
    #vision-conatiner{
        width: 100%;
    }
    .vision-conatiner{
        margin-top: 0 !important;
    }
    .title-container{
        width: 100%;
        left: 0;
    }
    .lower-container{
		width: 100%;
	}
	.left-logo-container{
		width: 100%;
	}
    .left-logo-container img{
        left: 0;
        bottom: -50px;
    }
	.right-para-container{
		width: 100%;
		padding-right: 0;
        padding-top: 0 !important;
	}
	.right-para-container p{
		text-align: center;
		padding-right: 0;
	}
    /*Tokenomics*/
    .tokonomics{
        width: 100%;
        overflow-x: hidden;
    }
    .tokenomics-title-container{
        width: 100%;
        left: 0;
    }
    .chart-container{
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .piechart{
        width: 150% !important;
        position: relative !important;
        right: 24% !important;
    }
    /*.piechart{
        width: 120% !important;
    }
   /* .chart-container{
        margin-left: 0;
    }
    .piechart{
        width: 100px;
        height: 100px;
    }*/
    /*ROadmap*/
    #roadmap{
        width: 100%;
        overflow-x: hidden;
    }
    .roadmap-title-container{
        width: 100%;
        left: 0;
    }
    .roadmap .roadmap-area{
		background: none;
	}

    .heading h2{
        font-size: 40px;
    }

		.roadmap_image{
			display: none;
		}
		.roadmap_centerbar{
			display: none;
		}
		.roadmap-paragraphs{
			text-align: left !important;
            margin-left: -80px !important;
		}

		.hoverimg1:hover .roadmap_image{
			display: none !important;
		}
		
		.hoverimg2:hover .roadmap_image{
			display: none !important;
		}
		
		.hoverimg3:hover .roadmap_image{
			display: none !important;
		}

        /*nft*/
        .team-title-container{
            width: 100%;
            left: 0;
        }

        /* COntact */
        #contact-section {
            width: 100%;
        }
        .contact-title-container{
            width: 100%;
            left: 0;
        }
        .contact-title-container h2{
            text-align: center;
        }
        .contactForm{
            width: 100%;
            text-align: center !important;
          }
          #input-field{
            width: 100% !important;
          }
          #text-field{
            width: 100% !important;
          }
          .submit-btn{
            width: 50%;
          }

          /*social icons*/
          .social-container{
            width: 100%;
            margin-top: 40%;
            margin-bottom: 10%;
          }
}