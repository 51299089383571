.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


/*font*/
@font-face {
	font-family: Speedrush;
	src: url(./fonts/SpeedrushRegular-qZWp6.otf);
}








html{
	width: 100%;
	height: auto;
	background: url("../src/assets/images/bg.png") 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

/*Body*/
.body{
	width: 100%;
	height: auto;
	background: url("../src/assets/images/bg.png") 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}


/* Header Csss */
#logo-heading{
	font-family: 'Speedrush';
	font-size: 30px;
}
.header-container{
  width: 100%;
  height: 150vh;
  background: url("../src/assets/images/header/titlepage.png") 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow-x: hidden !important;
}
.navbarBg{
  background-image: url("../src/assets/images/header/header.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.links{
  text-align: center;
  margin-left: 17%;
}
.nav-items{
  color: white !important;
  margin-right: 20px;
  font-family: Speedrush !important;
  font-size: 20px;
}
/*video{
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
	object-fit: cover;
}*/
.header-logo{
  text-align: center;
	position: absolute;
	top: 300px;
	left: 0;
	right: 0;
}
.header-logo img{
	text-align: center;
	width: 50%;
}
.header-btn-container{
	text-align: center;
	font-size: 0;
	
	position: absolute;
	left: 0;
	right: 0;
	top: 70%;
}

.bttn{
	display: inline-block;
	text-align: center;
	margin-right: -150px;
	margin-left: -150px;
	
	/*left: -14%;
	margin-right: -430px;
	padding-right: 0;*/
	
}
.bttn img{
	width: 40%;
	height: 40%;
}
/*
.logo-class img{
	width: 50px;
}*/

/* About css */
#vision-conatiner{
  width: 100%;
  height: auto;
  margin-top: -60px !important;
  text-align: center;
	position: relative;
	/*padding: 0 0 150px;*/
}
.vision-conatiner{
  /*background: url("../src/assets/snake/text-bg.png") no-repeat 50% 50%;
	background-size: 100% 100%;
	object-fit: cover;*/
	width: 100%;
	height: 100%;
	text-align: center;
	padding: 10px;

  margin-top: 80px;
}

.title-container{
	height: 90px;
	width: 25%;
	background: url("../src/assets/images/about/about.png") no-repeat 50% 50%;
	background-size: contain;
	object-fit: cover;
	text-align: center;
	padding: 10px;
	position: relative;
	left: 37%;
	margin-top: 60px;
}


.lower-container{
	padding: 10px;
	width: 100%;
	text-align: center;
	margin-bottom: 30px;
}
.left-logo-container{
	width: 40%;
	float: left;
	padding: 10px;
}
.left-logo-container img{
	width: 250px;
	padding-bottom: 50px;
  position: relative;
  bottom: 30px;
  left: 20px;
}
.right-para-container{
	width: 60%;
	float: right;
	padding: 10px;
  padding-top: 30px !important;
}
.right-para-container p{
	color: white;
	text-align: left;
	padding-left: 0;
	padding-right: 120px;
	font-family: Speedrush;
	letter-spacing: 1px;
}
.clear:after {
    clear: both;
    content: "";
    display: table;
}

/* tokonomics */
.tokonomics{
  width: 100%;
  height: auto;
  /*padding: 100px 0;*/
	text-align: center;
	position: relative;
	padding: 60px;
}
.tokenomics-title-container{
	height: 90px;
	width: 25%;
	background: url("../src/assets/images/tokennomics.png") no-repeat 50% 50%;
	background-size: contain;
	object-fit: cover;
	text-align: center;
	padding: 10px;
	position: relative;
	left: 37%;
	margin-top: 60px;
}
.chart-container{
  align-items: center;
  justify-content: center;

  /*padding: top right bottom left*/
  padding: 80px 400px 0px 400px;
}


/* Roadmap Css */
#roadmap{
  width: 100%;
  height: auto;
  text-align: center;
	z-index: 3;
	position: relative;
	/*padding: 150px 0 100px;*/
}

.roadmap .roadmap-area {
	position: relative;
	/*background: url("../images/roadmap/roadmap_center_bar.png");*/
	
	/*background-size: 240px 40%;*/
	background-size: contain;
	background-repeat: no-repeat;
	background-position: 50% 50%;

}
.roadmap .roadmap-area:before {
	position: absolute;
	width: 1px;
	height: 100%;
	left: 50%;
	border-bottom-left-radius: 50%;
	border-bottom-right-radius: 50%;
}
.roadmap .roadmap-area .roadmap-tree .roadmap-cloumn {
	align-items: center;
	display: flex;
	justify-content: center;
	margin-bottom: 50px;
	position: relative;
}
.roadmap .roadmap-area .roadmap-tree .roadmap-cloumn:last-child {
	margin-bottom: 0;
}
.roadmap .roadmap-area .roadmap-tree .roadmap-cloumn:hover .roadmap-desc .doc-wraper:before {
	opacity: 1;
}
.roadmap .roadmap-area .roadmap-tree .roadmap-cloumn .roadmap-marker {
	position: absolute;
	top: 0%;
	left: 50%;
	margin-top: -10px;
	margin-left: -10px;
	width: 20px;
	height: 20px;
}
.roadmap .roadmap-area .roadmap-tree .roadmap-cloumn .roadmap-marker:before {
	position: absolute;
	content: "";
	width: 11px;
	height: 11px;
	top: 50%;
	left: 50%;
	margin-top: -5px;
	margin-left: -4.5px;
	border-radius: 50%;
}
.roadmap .roadmap-area .roadmap-tree .roadmap-cloumn .roadmap-marker.marker-off:before {
}
.roadmap .roadmap-area .roadmap-tree .roadmap-cloumn .roadmap-dese-left {
	padding: 0 65px 0 0;
}
.roadmap .roadmap-area .roadmap-tree .roadmap-cloumn .roadmap-dese-right {
	padding: 0 0px 0 65px;
}
.roadmap .roadmap-area .roadmap-tree .roadmap-cloumn .roadmap-desc {
	min-height: 120px;
} 
.roadmap .roadmap-area .roadmap-tree .roadmap-cloumn .roadmap-desc .doc-wraper {
	padding: 70px 40px;
	position: relative;
	z-index: 1;
	height: 100%;
	width: 100%;

	padding-top: 120px !important;
	padding-bottom: 120px !important;
}
.roadmap .roadmap-area .roadmap-tree .roadmap-cloumn .roadmap-desc .doc-wraper:before {
	position: absolute;
	/*background: url("../images/roadmap/roadmap_button.png") 50% 50%;
	background-size: 100% 100%;
	object-fit: cover;*/
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	content: "";
	opacity: 0;
	transition: all 0.3s ease-in-out;
	z-index: -1;
}
.roadmap .roadmap-area .roadmap-tree .roadmap-cloumn .roadmap-desc .doc-wraper h4 {
	font-size: 20px;
	margin-bottom: 30px;
	line-height: 30px;

	position: relative;
	right: 45%;
	bottom: 90px;
}

.roadmap .roadmap-area .roadmap-tree .roadmap-cloumn .roadmap-status {
	min-height: 185px;
	padding: 0 35px;
	position: relative;
}
.roadmap .roadmap-area .roadmap-tree .roadmap-cloumn .roadmap-status span {
	display: inherit;
	font-weight: 600;
	font-size: 20px;
	text-transform: uppercase;
}
.roadmap .roadmap-area .roadmap-tree .roadmap-cloumn .roadmap-status span:last-child {
	font-size: 16px;
	font-weight: 500;
}
.roadmap .roadmap-area .roadmap-tree .roadmap-cloumn .roadmap-left {
	text-align: right;
}
.roadmap .roadmap-area .roadmap-tree .roadmap-cloumn .roadmap-right {
	text-align: left;
}


.heading{
	width: 100%;
	height: 10px;
	text-align: center;
	padding-bottom: 150px;
  }
  .roadmap-title-container{
	height: 90px;
	width: 25%;
	background: url("../src/assets/images/roadmap/roadmapbutton.png") no-repeat 50% 50%;
	background-size: contain;
	object-fit: cover;
	text-align: center;
	padding: 10px;
	position: relative;
	left: 37%;
	margin-top: 60px;
}
  .heading h2{
	/*background: url("../images/roadmap/roadmap_title_bar.png") no-repeat 50% 50%;
	background-size: 100% 100%;
	object-fit: cover;*/
	text-align: center;
	color: white;
	padding-top: 60px;
	padding-bottom: 37px;
	font-family: Speedrush;
  }
  .roadmap_image{
	width: 200px;
  }
  .roadmap_image img{
	width: 200px;
	position: relative;
	left: 80px;


	/*
	position: absolute;
	left: 900px;
	right: 0;*/
  /*margin-left: 60px;
  margin-top: 20px;*/
  }
  .inline{
	text-align: center;
	display: inline-block;

  }
  .roadmap_centerbar{
	width: 80px;
	margin-bottom: 60px;
  }
  .roadmap_centerbar img{
	width: 80px;
	text-align: center;
	position: absolute;
	left: 48%;
  }

#roadmap .sub-title{
	/*background: url("../images/roadmap.png") no-repeat 50% 50%;
	background-size: 100% 100%;
	object-fit: cover;*/
	text-align: center;
	z-index: -99;
	height: 90px;
	width: 25%;
}
.roadmap .container{
	z-index: 101;
	position: relative;
	top: -150px;
}
#roadmap .sub-title h2{
	color: white;
	position: relative;
	top: 25px;
	font-family: machine-gunk-font;
}

.roadmap-paragraphs ol li p{
	text-align: left;
}

ol li{
	list-style-type: none;
	background-image: url(../src/assets/images/roadmap/circle1.png);
	background-position: 0 5px;
	background-size: 1.4rem 1.4rem;
	background-repeat: no-repeat;
  }
  

.roadmap .roadmap-area .roadmap-tree .roadmap-cloumn .roadmap-desc .doc-wraper p {
	line-height: 25px;
	color: white;
	font-family: oh-whale;
	padding-left: 50px !important;
}

#roadmap .doc-wraper h4{
	color: white;
	font-family: machine-gunk-font;
}
.contact-bgimg{
	position: absolute;
	width: 100%;
	height: auto;
	bottom: 0;
	z-index: 1;
}

.roadmap-bgimg{
	position: absolute;
	width: 100%;
	height: 100%;
	bottom: 0;
	z-index: 10;
}

/*
.hoverimg1:hover .roadmap_image{
	position: absolute;
	display: block !important;
}

.hoverimg2:hover .roadmap_image{
	position: absolute;
	display: block !important;
}

.hoverimg3:hover .roadmap_image{
	position: absolute;
	display: block !important;
}
*/



/* NFT's */

#nft-container{
	width: 100%;
	height: auto;
	padding: 5px;
}
.team-container{
	width: 100%;
	height: auto;
	text-align: center;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 100px;
}
.team-title-container{
	height: 90px;
	width: 25%;
	background: url("../src/assets/images/nft.png") no-repeat 50% 50%;
	background-size: contain;
	object-fit: cover;
	text-align: center;
	position: relative;
	left: 37%;
}
.team-title-container h2{
	color: white;
}
.team-members-container{
	text-align: center;
	padding: 10px;
	width: 100%;
	height: auto;
}
.member1{
	width: 250px;
	height: 250px;

	border-radius: 10px;	
	display: inline-block;
}
.member1 img{
	width: 160px;
	height: 160px;
	border-radius: 10px;
	border-color: transparent;
	text-align: center;
	position: relative;

	top: 90px;	

}

/*Contact*/
/* Contact form */
#contact-section{
  width: 100%;
  height: auto;
  /*padding: 150px 0;*/
 /* background-image: url("../src/assets/snake/CONTACT-US.png");
  background-repeat: no-repeat;
  background-size: cover;*/
}
#contact-section .container{
	position: relative;
	top: 100px;
}
.contact-title-container{
	height: 90px;
	width: 25%;
	background: url("../src/assets/images/contact.png") no-repeat 50% 50%;
	background-size: contain;
	object-fit: cover;
	text-align: center;
	padding: 10px;
	position: relative;
 	top: 30px;
	left: 37%;
	margin-bottom: 60px;
}
.contact-title-container h2{
  color: white;
	padding: 10px;
	text-align: center;
	font-size: 40px;
}
.contactForm{
  text-align: center;
}
#input-field{
	font-family: Speedrush;
  width: 40%;
  background-color: rgba(0, 0, 0, 0.5) !important;
  border: 1px solid #686464 !important;
  border-radius: 10px !important;
  color: white !important;
}
#input-field::placeholder{
	color: #BFBFC6;
	opacity: 1;
	font-family: Speedrush;
}
#text-field{
  width: 40%;
  height: 200px;
  background: rgba(0, 0, 0, 0.5) !important; 
  border-radius: 10px !important;
  border: 1px solid #686464 !important;
  color: white !important;
  font-family: Speedrush;
}
#text-field::placeholder{
  color: #BFBFC6;
	opacity: 1;
	font-family: Speedrush;
}
.submit-btn{
  background: url("../src/assets/images/send.png") 50% 50%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border: none;
  padding: 40px;
  height: 90px;
  width: 15%;
}

/*Icons*/
.social-container{
	width: 100%;
	height: auto;
	text-align: center;
	margin-top: 8%;
	margin-bottom: 2%;
}
.social{
	padding-right: 15px;
	color: white;
	text-align: center;
	position: relative;
	left: 8px;
}


    /****** Popup box ******/
    .overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.7);
        transition: opacity 500ms;
        visibility: hidden;
        opacity: 0;
        z-index: 100;
       }
       .overlay:target {
        visibility: visible;
        opacity: 1;
       }
       
       .popup2 {
        margin: 70px auto;
        padding: 20px;
        /*background: linear-gradient(0deg, #660000, #a00003);*/
        background: url("../src/assets/images/bg.png") 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        border-radius: 5px;
        width: 30%;
        position: relative;
        top: 50px;
        transition: all 5s ease-in-out;
        z-index: 100;
       }
       
       .popup2 h2 {
        color: white;
        font-family: Tahoma, Arial, sans-serif;
        text-align: center;
        padding: 50px;
        font-family: ARCO;
       }
       .popup2 .close2 {
        position: absolute;
        top: 20px;
        right: 30px;
        transition: all 200ms;
        font-size: 30px;
        font-weight: bold;
        text-decoration: none;
        color:white;
       }
       .popup2 .content2 {
        max-height: 30%;
        overflow: auto;
       }
       
       @media screen and (max-width: 700px){
        .box{
        width: 70%;
        }
        .popup2{
        width: 70%;
        }
       }
    
       .popup .popup2 .popup-content{
           width: 100%;
           text-align: center;
           align-items: center;
       }